/**
 * Use the horizontal large article row to display 1 piece of content with high visual importance and you want to utilize the full width of the grid.
 *
 * @module views/components/ArticleRowHorizontalLarge
 * @memberof -Common
 */
import './ArticleRowHorizontalLarge.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import Tag from '@ulta/components/Tag/Tag';

import { constants } from '@ulta/utils/constants/constants';

/**
 * Represents a CreateArticles function
 * This function will take in the article data from user and format it for the Article Row Horizontal Large
 * @method
 * @param {object} data - All of the article data passed through
 * @returns The article
 */

const CreateArticles = ( data, hasBackground ) => {
  return (
    data.map( ( content, index ) => (
      <React.Fragment key={ index }>
        <div className='ArticleRowHorizontalLarge__contentImage'>
          { content.image && (
            <UltaAsset
              { ...content.image }
            />
          ) }
        </div>
        <div className='ArticleRowHorizontalLarge__contentDetails'>
          { content.tag &&
            <div className='ArticleRowHorizontalLarge__contentTag'>
              <Tag
                messageText={ content.tag }
                messageType={ hasBackground ? 'white' : content.tagDisplayType === undefined ? 'contextual' : content.tagDisplayType }
              />
            </div>
          }
          { content.eyebrow &&
            <div className='ArticleRowHorizontalLarge__contentEyebrow'>
              <Text
                htmlTag='p'
                textStyle='eyebrow'
              >
                { content.eyebrow }
              </Text>
            </div>
          }
          <div className='ArticleRowHorizontalLarge__contentTitle'>
            <Text htmlTag='h3'
              textStyle='title-5'
            >
              { content.title }
            </Text>
          </div>
          <div className='ArticleRowHorizontalLarge__contentTitlesupport'>
            <Text htmlTag='p'
              textStyle='body-1'
            >
              { content.titleSupport }
            </Text>
          </div>
          { content.subtitle &&
            <div className='ArticleRowHorizontalLarge__contentSubTitle'>
              <Text
                htmlTag='h4'
                textStyle='title-6'
              >
                { content.subtitle }
              </Text>
            </div>
          }
          { content.subtitleSupport &&
            <div className={ classNames( 'ArticleRowHorizontalLarge__contentSubTitlesupport', {
              ['ArticleRowHorizontalLarge__contentSubTitlesupport--noSubtitle']:!content.subtitle
            }
            ) }
            >
              <Text
                htmlTag='p'
                textStyle='body-2'
              >
                { content.subtitleSupport }
              </Text>
            </div>
          }
          { content.subtitle2 &&
            <div className='ArticleRowHorizontalLarge__contentSubTitle--02'>
              <Text
                htmlTag='h4'
                textStyle='title-6'
              >
                { content.subtitle2 }
              </Text>
            </div>
          }
          { content.subtitleSupport2 &&
            <div className={ classNames( 'ArticleRowHorizontalLarge__contentSubTitlesupport--02', {
              ['ArticleRowHorizontalLarge__contentSubTitlesupport--noSubtitle02']:!content.subtitle2
            }
            ) }
            >
              <Text
                htmlTag='p'
                textStyle='body-2'
              >
                { content.subtitleSupport2 }
              </Text>
            </div>
          }
          { ( content.buttonAction?.label || content.linkAction?.label ) &&
            <div className={ classNames( 'ArticleRowHorizontalLarge__actions', {
              ['ArticleRowHorizontalLarge__actions--noButton']:!content.buttonAction
            }
            ) }
            >
              { content.buttonAction && content.buttonAction?.label &&
                <div className='ArticleRowHorizontalLarge__contentButton' >
                  <Button
                    label={ content.buttonAction.label }
                    target='_self'
                    url={ content.buttonAction.url }
                    action={ content.buttonAction }
                    ariaLabel={ content.buttonAction.label }
                    withHover
                  />
                </div>
              }
              { content.linkAction?.label &&
                <div className='ArticleRowHorizontalLarge__contentLabel'>
                  <Link_Huge
                    target='_self'
                    action={ content.linkAction }
                    withHover
                  >
                    { content.linkAction.label }
                  </Link_Huge>
                </div>
              }
            </div>
          }
        </div>
      </React.Fragment>
    ) )
  );
};

/**
 * Represents a ArticleRowHorizontalLarge component
 *
 * @method
 * @param {ArticleRowHorizontalLargeProps} props - React properties passed from composition
 * @returns ArticleRowHorizontalLarge
 */
export const ArticleRowHorizontalLarge = function( props ){
  const {
    split,
    inset,
    backgroundColor,
    article,
    title,
    eyebrow,
    subtitle,
    mirrored,
    root,
    rootMargin,
    threshold
  } = props;
  const articleRowHorLargeRef = useRef( null );
  const hasBackground = backgroundColor && !inset;
  useIntersectionObserver( articleRowHorLargeRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !article ){
    return null;
  }
  return (
    <div className={
      classNames( 'ArticleRowHorizontalLarge', {
        [`ArticleRowHorizontalLarge__withBackgroundColor ArticleRowHorizontalLarge__withBackgroundColor--${backgroundColor}`]:backgroundColor,
        ['ArticleRowHorizontalLarge--split']:split,
        ['ArticleRowHorizontalLarge--inset']:inset
      } )
    }
    ref={ articleRowHorLargeRef }
    >
      <div className='ArticleRowHorizontalLarge__topSection'>
        <GridContainer>
          { eyebrow &&
            <div className='ArticleRowHorizontalLarge__headingEyebrow'>
              <Text
                htmlTag='p'
                textStyle='eyebrow'
                textAlign='center'
              >
                { eyebrow }
              </Text>
            </div>
          }
          { title?.text &&
            <div className='ArticleRowHorizontalLarge__headingTitle'>
              <Text
                htmlTag={ title.htmlTag }
                textStyle='title-4'
                textAlign='center'
              >
                { title.text }
              </Text>
            </div>
          }
          { subtitle &&
            <div className='ArticleRowHorizontalLarge__headingSubtitle'>
              <Text
                htmlTag='p'
                textStyle='subtitle-1'
                textAlign='center'
              >
                { subtitle }
              </Text>
            </div>
          }
        </GridContainer>
      </div>
      <div className={ classNames( 'ArticleRowHorizontalLarge__bottomSection', {
        ['ArticleRowHorizontalLarge__bottomSection--mirrored']:mirrored
      }
      ) }
      >
        <GridContainer>
          { article &&
            CreateArticles( article, hasBackground )
          }
        </GridContainer>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ArticleRowHorizontalLargeProps
 * @type {object}
 * @property {object} title - Sets the title properties
 * @property {string} eyebrow - Sets the eyebrow
 * @property {string} subtitle - Sets the subtitle
 * @property {string} backgroundColor - Sets the backgroundColor
 * @property {array} article - Sets the article properties
 * @property {object} linkAction - Sets the linkAction
 * @property {boolean} mirrored - Boolean to change the layout to be mirrored
 * @property {boolean} inset - Boolean to change the layout to be inset
 * @property {boolean} split - Boolean to change the layout to be split 50/50
 */

export const propTypes = {
  title: PropTypes.object,
  eyebrow: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  article: PropTypes.array,
  linkAction: PropTypes.object,
  mirrored: PropTypes.bool,
  inset: PropTypes.bool,
  split: PropTypes.bool
};

/**
* Default values for passed properties
* @type object
*/
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

ArticleRowHorizontalLarge.defaultProps = defaultProps;
ArticleRowHorizontalLarge.propTypes = propTypes;

export default ArticleRowHorizontalLarge;